import axios from 'axios';
import { encryptData, decryptData } from './encryptionUtils';

const axiosInstance = axios.create();

// Request interceptor to encrypt the data
axiosInstance.interceptors.request.use(
  (config) => {
    if (config.method === 'post' && config.data) {
      config.data = { data: encryptData(JSON.stringify(config.data)) };
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

// Response interceptor to decrypt the data
axiosInstance.interceptors.response.use(
  (response) => {
    if (response.data && response.data.user) {
      response.data.user = JSON.parse(decryptData(response.data.user));
    }
    return response;
  },
  (error) => {
    return Promise.reject(error);
  }
);

export default axiosInstance;