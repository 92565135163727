import React, { useState } from "react";
import { Radio } from "antd";

const { Group: RadioGroup } = Radio;
const radioStyle = {
  display: "block",
  height: "30px",
  lineHeight: "30px",
};

const RadioType = ({ insertVariable, setData }) => {
  const [value, setValue] = useState(insertVariable.options[0].value);
  const handleSelect = (e) => {
    e.preventDefault();
    setData(e.target.value);
    setValue(e.target.value);
  };

  return (
    <div className="flex justify-center p-12 w-full">
      <div className="w-full">
        {/* <h3 className="text-xl sm:text-2xl font-semibold text-gray-800 mb-3">
          {insertVariable.title}
        </h3> */}
        <p className="text-gray-600 mb-6">{insertVariable.title}</p>
        <RadioGroup onChange={handleSelect} value={value} className="w-full">
          {insertVariable.options.map((option) => (
            <Radio style={radioStyle} value={option.value} key={option.value}>
              {option.label}
            </Radio>
          ))}
        </RadioGroup>
      </div>
    </div>
  );
};

export default RadioType;
