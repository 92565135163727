import React, { useState, useContext, useEffect, useMemo } from "react";
import { Spin } from "antd";
import { RightOutlined, LeftOutlined } from "@ant-design/icons";
import { Outlet, useNavigate, useLocation } from "react-router-dom";
import Sidebar from "../Sidebar";
import { AuthContext } from "../AuthContext";
import Navbar from "../components/Navbar";

const Dashboard = () => {
  const { loading, user, loggedIn } = useContext(AuthContext);
  const [isSidebarVisible, setIsSidebarVisible] = useState(true);
  const navigate = useNavigate();
  const location = useLocation();

  const showSidebar = useMemo(() => {
    const pathsToHideSidebar = ["onboarding", "controluser", "admin"];
    return !pathsToHideSidebar.some((path) => location.pathname.includes(path));
  }, [location.pathname]);

  useEffect(() => {
    const auth = JSON.parse(localStorage.getItem("auth"));
    if (!auth) {
      navigate("/");
    }
  }, [loggedIn, user, navigate]);

  return (
    <div className="h-full bg-white">
      {loading && (
        <div className="h-full w-full absolute z-50 flex justify-center items-center">
          <Spin size="large" />
          <div className="h-full w-full absolute z-40 bg-black opacity-10"></div>
        </div>
      )}
      <div className="hidden sm:flex flex-col sm:flex-row sm:flex-nowrap overflow-y-clip h-full">
        <div className="hidden sm:flex">
          <Navbar />
        </div>
        {!showSidebar && (
          <div className="contents sm:hidden">
            <div className="relative">
              <div className="flex justify-between items-center px-2 h-[84px] bg-[#464646]">
                <img src={`${process.env.PUBLIC_URL}/logo.png`} alt="logo" className="app-logo mx-auto" />
              </div>
            </div>
          </div>
        )}
        {showSidebar && (
          <div className="relative">
            <div
              className={
                isSidebarVisible
                  ? "sm:w-[250px] sm:h-full border-l border-white"
                  : "hidden"
              }
            >
              <Sidebar />
            </div>
            <div
              className={`absolute top-5 ${
                isSidebarVisible ? "left-[255px]" : "left-2"
              } animate hidden sm:contents`}
            >
              <div
                className="flex w-10 h-10 justify-center items-center rounded-md bg-sidebarColor text-white transform transition-all duration-50 ease-in-out hover:scale-110 hover:bg-activebuttonColor"
                onClick={() => setIsSidebarVisible(!isSidebarVisible)}
              >
                {isSidebarVisible ? <LeftOutlined /> : <RightOutlined />}
              </div>
            </div>
          </div>
        )}
        <div className="main-bg sm:flex justify-center grow overflow-x-auto bg-mainColor hidden">
          <Outlet />
        </div>
      </div>
      <div className="sm:hidden flex flex-col fixed inset-0">
        <div className="h-[84px]">
          <Sidebar />
        </div>
        <div className="flex-grow overflow-y-auto flex">
          <Outlet />
        </div>
        <div className="h-[80px]">
          <Navbar />
        </div>
      </div>
    </div>
  );
};

export default Dashboard;
