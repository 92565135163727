import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { notification } from "antd";
import axios from "axios";
import { webAPIs } from "../config/webAPIs";

const ForgotPassword = () => {
  const [email, setEmail] = useState("");
  const navigate = useNavigate();

  const handleEmailChange = (event) => {
    setEmail(event.target.value);
  };

  const handleFormSubmit = async (event) => {
    event.preventDefault();
    try {
      // Replace with your actual API endpoint
      const { data } = await axios.post(webAPIs.forgetPassword, { email });
      if (data.success) {
        notification.success({
          message: "Success",
          description: data.message,
          duration: 5,
        });
        // Optionally navigate to the sign-in page or a confirmation page
      } else {
        notification.error({
          message: "Error",
          description: data.message,
          duration: 5,
        });
      }
    } catch (error) {
      notification.error({
        message: "",
        description:  error.response?.data?.message || error.message || "An error occurred while attempting to reset password.",
        duration: 5,
      });
    }
  };

  return (
    <div className="min-h-screen flex items-center justify-center bg-black">
      <div className="bg-white p-8 rounded shadow-md w-full max-w-md">
        <h2 className="text-2xl font-bold mb-10 text-gray-800">
          Forgot Password
        </h2>
        <form className="space-y-6" onSubmit={handleFormSubmit}>
          <div>
            <label className="text-sm font-bold text-gray-600 block">
              Email address
            </label>
            <input
              type="email"
              className="w-full p-2 border border-gray-300 rounded mt-1"
              placeholder="Enter your email"
              value={email}
              onChange={handleEmailChange}
              required
            />
          </div>
          <div>
            <button
              type="submit"
              className="w-full py-2 px-4 bg-blue-500 hover:bg-blue-700 rounded-md text-white text-sm font-bold"
            >
              Send Reset Instructions
            </button>
          </div>
        </form>
        <div className="text-center mt-6">
          Remembered your password?
          <Link to="/sign-in" className="text-blue-600 hover:underline ml-1">
            Sign in
          </Link>
        </div>
      </div>
    </div>
  );
};

export default ForgotPassword;
