import { useContext, useEffect, useState } from "react";
import { Button, Spin, Input, notification } from "antd";
import axios from "axios";
import { webAPIs } from "../config/webAPIs";
import { AuthContext } from "../AuthContext";
import { useNavigate } from "react-router-dom";
const { TextArea } = Input;

const ScrapeUrl = (props) => {
  const { loggedIn, user, handleResetMessage } = useContext(AuthContext);

  const [scrapeFiles, setScrapeFiles] = useState([]);
  const [text, setText] = useState("");
  const [url, setUrl] = useState("");
  const [loading, setLoading] = useState(false);
  const [isAddURL, setIsAddURL] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    const auth = JSON.parse(localStorage.getItem("auth"));
    if (auth) {
      handleScrapeFiles();
    }
  }, [loggedIn]);

  const handleScrapeFiles = async () => {
    if (user) {
      try {
        setLoading(true);
        const response = await axios.post(webAPIs.getScrapeFiles, {
          user: user.id,
        });
        if (response.data.success) {
          setScrapeFiles(response.data.data);
        }
      } catch (error) {
        console.log(error);
      } finally {
        setLoading(false);
      }
    }
  };

  const isValidUrl = (urlString) => {
    try {
      new URL(urlString);
      return true;
    } catch (error) {
      return false;
    }
  };

  const handleScrapeUrl = async (url, id) => {
    if (isValidUrl(url)) {
      if (scrapeFiles.find(scrape => scrape.fileName === url)) {
        notification.error({
          description: 'This URL already exist.',
          duration: 2,
        });
        return
      }
      try {
        setLoading(true);
        const response = await axios.post(webAPIs.scrapeURL, {
          user: user.id,
          url: url,
          // annoymisation,
          annoymisation: false
        });
        let temp = JSON.parse(JSON.stringify(scrapeFiles));
        temp[id].text = response.data.text;
        setScrapeFiles(temp);
        if (response.data.success) {
          notification.success({
            description: "Scraping Finished",
            duration: 2,
          });
          handleScrapeFiles();
          handleResetMessage();
        }
      } catch (e) {
        console.error(e);
        notification.error({
          description: e.response?.data?.error || "Server Error",
          duration: 2,
        });
      } finally {
        setLoading(false);
        setIsAddURL(false);
        setUrl("");
      }
    } else {
      notification.error({
        description: 'This is not a valid URL.',
        duration: 2,
      });
    }
  };

  return (
    <>
      {loading ? (
        <Spin />
      ) : (
        <div className="w-full sm:h-full sm:p-5 p-2 sm:pl-20 flex flex-col gap-5 ">
          <span className="sm:text-xl font-medium text-center">
            Here you can view the Scrape URLs
          </span>
          <Button
            className="bg-sidebarColor text-white sm:w-2/5"
            disabled={isAddURL}
            onClick={() => {
              setScrapeFiles([...scrapeFiles, { fileName: "", text: "" }]);
              setIsAddURL(true);
            }}
          >
            Add URL
          </Button>
          <div className="flex sm:flex-row flex-col sm:py-5 sm:gap-5 gap-2">
            <div className="sm:w-1/2 w-full flex flex-col gap-3">
              {scrapeFiles.map((data, id) => (
                <div className="flex gap-5" key={id}>
                  <Input
                    readOnly={data.fileName !== ""}
                    className="flex-1"
                    placeholder="https://www.data-iq.ch/"
                    value={data.fileName ? data.fileName : url}
                    onChange={(e) => {
                      setUrl(e.target.value);
                    }}
                  />
                  <Button
                    className="bg-sidebarColor text-white"
                    disabled={data.fileName !== "" || !url}
                    onClick={() => {
                      handleScrapeUrl(url, id);
                    }}
                  >
                    Scrape
                  </Button>
                  {data.text && (
                    <Button
                      className="bg-sidebarColor text-white"
                      onClick={() => {
                        setText(data.text);
                      }}
                    >
                      View
                    </Button>
                  )}
                </div>
              ))}
            </div>
            <div className="sm:w-1/2 w-full">
              <TextArea cols={50} rows={20} value={text} />
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default ScrapeUrl;
