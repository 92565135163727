export const webAPIs = {
    //password
    signIn: `${process.env.REACT_APP_SERVER_URL}user/password`,
    signUp: `${process.env.REACT_APP_SERVER_URL}user/register`,
    changePassword: `${process.env.REACT_APP_SERVER_URL}user/changePassword`,
    resetPassword: `${process.env.REACT_APP_SERVER_URL}user/resetPassword`,
    forgetPassword: `${process.env.REACT_APP_SERVER_URL}user/forgetPassword`,
    resetPasswordConfirmation: `${process.env.REACT_APP_SERVER_URL}user/resetPasswordConfirmation`,

    //user
    getUser: `${process.env.REACT_APP_SERVER_URL}user/getUser`,
    addUser: `${process.env.REACT_APP_SERVER_URL}user/addUser`,
    changeUser: `${process.env.REACT_APP_SERVER_URL}user/changeUser`,
    deleteUser: `${process.env.REACT_APP_SERVER_URL}user/deleteUser`,
    getUsers: `${process.env.REACT_APP_SERVER_URL}user/getUsers`,
    changeInformation: `${process.env.REACT_APP_SERVER_URL}user/changeInformation`,
    
    //conversation
    getConversation: `${process.env.REACT_APP_SERVER_URL}conversation/getConversation`,
    clearConversation: `${process.env.REACT_APP_SERVER_URL}conversation/clearConversation`,

    //change Account
    changeSystemPrompt: `${process.env.REACT_APP_SERVER_URL}conversation/changeSystemPrompt`,
    changeDocument: `${process.env.REACT_APP_SERVER_URL}conversation/changeDocument`,
    addDocument: `${process.env.REACT_APP_SERVER_URL}conversation/addDocument`,

    //chat
    chat: `${process.env.REACT_APP_SERVER_URL}conversation/chat`,

    //create knowledge graph
    knowledgeGraph: `${process.env.REACT_APP_SERVER_URL}conversation/knowledgeGraph`,

    //select role and system variables
    roleandvariables: `${process.env.REACT_APP_SERVER_URL}user/roleandvariables`,
    addInsertVarialble: `${process.env.REACT_APP_SERVER_URL}user/addInsertVariable`,
    editInsertVariable: `${process.env.REACT_APP_SERVER_URL}user/editInsertVariable`,
    deleteInsertVariable: `${process.env.REACT_APP_SERVER_URL}user/deleteInsertVariable`,
    getInsertVariables: `${process.env.REACT_APP_SERVER_URL}user/getInsertVariables`,
    addSystemRole: `${process.env.REACT_APP_SERVER_URL}user/addSystemRole`,
    editSystemRole: `${process.env.REACT_APP_SERVER_URL}user/editSystemRole`,
    deleteSystemRole: `${process.env.REACT_APP_SERVER_URL}user/deleteSystemRole`,
    getSystemRoles: `${process.env.REACT_APP_SERVER_URL}user/getSystemRoles`,

    getAudioFiles: `${process.env.REACT_APP_SERVER_URL}conversation/getAudioFiles`,
    getScrapeFiles: `${process.env.REACT_APP_SERVER_URL}conversation/getScrapeFiles`,
    scrapeURL: `${process.env.REACT_APP_SERVER_URL}conversation/scrapeURL`,

    //Subscriptions
    getSubscriptions: `${process.env.REACT_APP_SERVER_URL}user/getSubscriptions`,
    changeSubscription: `${process.env.REACT_APP_SERVER_URL}user/chagneSubscription`,
    createPortalSession: `${process.env.REACT_APP_SERVER_URL}user/createPortalSession`,
}