import React, { useState } from "react";
import Roles from "../components/Roles";
import Steps from "../components/Steps";
import { useLocation } from "react-router-dom";

const OnBoarding = () => {
  const { state } = useLocation();
  const [systemRole, setSystemRole] = useState(state?.systemRole || {});

  return (
    <div className="flex flex-col bg-botColor sm:p-4 gap-2 h-fit sm:h-full w-full">
      <div className="flex justify-center items-center font-semibold">
        Select your role!
      </div>
      <div className="flex-1">
        {Object.hasOwn(systemRole, "id") ? (
          <Steps systemRole={systemRole} />
        ) : (
          <Roles
            setSystemRole={(data) => {
              setSystemRole(data);
            }}
          />
        )}
      </div>
    </div>
  );
};

export default OnBoarding;
