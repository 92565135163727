import React from "react";

const InputType = ({ insertVariable, setData }) => {
  return (
    <div className="flex justify-center p-12 w-full">
      <div className="w-full">
        <p className="text-gray-600 mb-6">{insertVariable.title}</p>
        <input
          onChange={(e) => setData(e.target.value)}
          className=" border border-black w-full rounded-md p-2"
        />
      </div>
    </div>
  );
};

export default InputType;
