import React from "react";
import { Select } from "antd";

const { Option } = Select;

const DropdownType = ({ insertVariable, setData }) => {
  return (
    <div className="flex justify-center p-12 w-full">
      <div className="w-full">
        <p className="text-gray-600 mb-6">{insertVariable.title}</p>
        <div className="px-3 mb-4">
          <Select
            showSearch
            optionFilterProp="children"
            defaultValue={insertVariable.options[0]}
            className="w-full"
            onChange={(val) => setData(val)}
          >
            {insertVariable?.options.map((option) => (
              <Option key={option.value} value={option.value}>
                {option.label}
              </Option>
            ))}
          </Select>
        </div>
      </div>
    </div>
  );
};

export default DropdownType;
