import { useContext, useEffect, useState } from "react";
import { Button, Spin, Input } from "antd";
import axios from "axios";
import { webAPIs } from "../config/webAPIs";
import { AuthContext } from "../AuthContext";
import { useNavigate } from "react-router-dom";
const { TextArea } = Input;

const ViewTranscript = () => {
  const [audioFiles, setAudioFiles] = useState([]);
  const [text, setText] = useState("");
  const [loading, setLoading] = useState(false);
  const { user, loggedIn } = useContext(AuthContext);
  const navigate = useNavigate();

  useEffect(() => {
    const auth = JSON.parse(localStorage.getItem("auth"));
    if (auth) {
      handleAudioFiles();
    }
  }, [loggedIn, user]);

  const handleAudioFiles = async () => {
    if (user) {
      try {
        setLoading(true);
        const response = await axios.post(webAPIs.getAudioFiles, {
          user: user.id,
        });
        if (response.data.success) {
          setAudioFiles(response.data.data);
        }
      } catch (error) {
        console.log(error);
      } finally {
        setLoading(false);
      }
    }
  };

  return (
    <>
      {loading ? (
        <Spin />
      ) : (
        <div className="w-full sm:h-full sm:p-5 p-2 sm:pl-20 flex flex-col gap-5 ">
          <span className="sm:text-xl font-medium text-center">
            Here you can view the transcripts of your audio files
          </span>
          <div className="flex sm:flex-row flex-col sm:py-5 sm:gap-5 gap-2">
            <div className="sm:w-1/2 w-full">
              {audioFiles.map((audio, id) => (
                <div className="flex gap-5" key={id}>
                  <Input readOnly className="flex-1" value={audio.fileName} />
                  <Button
                    className="bg-sidebarColor text-white"
                    onClick={() => {
                      setText(audio.text);
                    }}
                  >
                    View
                  </Button>
                </div>
              ))}
            </div>
            <div className="sm:w-1/2 w-full">
              <TextArea cols={50} rows={20} value={text} />
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default ViewTranscript;
