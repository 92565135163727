import React, { useState, useContext, useEffect } from "react";
import axios from "axios";
import { Input, Button, notification, Image, Empty, Spin } from "antd";
import { AuthContext } from "../AuthContext";
import { webAPIs } from "../config/webAPIs";
import { DownloadOutlined } from "@ant-design/icons";
import { useNavigate } from "react-router-dom";

const KnowledgeComponent = (props) => {
  const [node, setNode] = useState("");
  const [edge, setEdge] = useState("");
  const { loggedIn, user } = useContext(AuthContext);
  const [loading, setLoading] = useState(false);
  const [kData, setKData] = useState(user?.kData);
  const { kSystemPrompt, kUserPrompt, kFontSize } = props;

  const onDownload = () => {
    fetch(kData)
      .then((response) => response.blob())
      .then((blob) => {
        const url = URL.createObjectURL(new Blob([blob]));
        const link = document.createElement("a");
        link.href = url;
        link.download = "image.png";
        document.body.appendChild(link);
        link.click();
        URL.revokeObjectURL(url);
        link.remove();
      });
  };

  const handleCreateClick = async (e) => {
    e.preventDefault();
    if (!node || !edge) {
      notification.error({
        description: "Please enter all input values.",
        duration: 2,
      });
      return;
    }
    setLoading(true);
    try {
      const response = await axios.post(webAPIs.knowledgeGraph, {
        node,
        kSystemPrompt,
        kUserPrompt,
        kFontSize,
        edge,
        user: user.id,
      });
      if (response.data.success) {
        setKData(response.data.message);
      }
    } catch (e) {
      notification.error({
        description: "Failed",
        duration: 2,
      });

      setLoading(false);
      console.log(e);
    }
    setLoading(false);
  };

  return (
    <div className="w-full h-full sm:px-5 sm:pb-5 p-2 sm:pl-20">
      <div className="w-ful h-full sm:px-5 flex">
        <div className="border w-full sm:h-full justify-center items-center flex relative overflow-hidden" >
          {kData ? (
            <Image className="w-full h-full" width="100%" src={kData} />
          ) : (
            <Empty />
          )}
          {loading && (
            <Spin
              size="large"
              className="absolute top-1/2 left-1/2 -translate-x-1/2"
            />
          )}
        </div>
        <DownloadOutlined className="w-10 h-10" onClick={() => onDownload()} />
      </div>
      <div className="flex w-full justify-center sm:gap-4 gap-2 py-2 sm:px-5">
        <Input
          placeholder="define what the nodes represent"
          allowClear
          onChange={(e) => setNode(e.target.value)}
        />
        <Input
          placeholder="define what the edges represent"
          allowClear
          onChange={(e) => setEdge(e.target.value)}
        />
        <Button
          className="w-full flex items-center justify-center text-white bg-sidebarColor h-10"
          onClick={handleCreateClick}
        >
          Create graphs
        </Button>
      </div>
    </div>
  );
};

export default KnowledgeComponent;
