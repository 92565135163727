import React from "react";

const Footer = () => {
  return (
    <footer className="footer-elt">
      <div className="footer-items">
        <div className="container mx-auto px-4 py-4">
          <div className="flex flex-col sm:flex-row justify-center items-center gap-4 text-center sm:text-left">
            <span className="footer-txt">
              <a
                href="https://insight-lab.ai"
                className="footer-txt hover:underline"
              >
                Insight-lab.ai
              </a>
            </span>
            <span>is a service of data IQ AG</span>
          </div>

          <div className="flex justify-center items-center gap-1 text-center">
            <span>Alte Steinhauserstrasse 33, 6330 Cham, Switzerland</span>
          </div>
          <div className="flex flex-col sm:flex-row justify-center items-center gap-1 text-center sm:gap-3 mt-2">
            <div>
              email:
              <a
                href="mailto:info@data-iq.ch"
                className="footer-txt hover:underline px-1"
              >
                info@data-iq.ch
              </a>
            </div>
            <span className="hidden sm:inline">|</span>
            <a
              href="https://www.data-iq.ch"
              className="footer-txt hover:underline px-1"
            >
              www.data-iq.ch
            </a>
            <span className="hidden sm:inline">|</span>
            <span>CHE-472.961.053</span>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;