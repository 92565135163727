import React, { useState, useEffect, useContext, useMemo, useCallback } from "react";
import {
  Table,
  Button,
  Spin,
  Modal,
  Form,
  Input,
  notification,
  Select,
  Slider,
  Checkbox,
  Row,
  Col,
} from "antd";
import axios from "axios";
import { AuthContext } from "../AuthContext";
import { webAPIs } from "../config/webAPIs";
import "react-phone-number-input/style.css";
import PhoneInput from "react-phone-number-input";
import countryList from "react-select-country-list";
import { useNavigate } from "react-router-dom";
import { formatNumber, subscriptionAlert } from "../utils";

const ControlUsers = () => {
  const { user } = useContext(AuthContext);

  const [users, setUsers] = useState([]);
  const [loading, setLoading] = useState(true);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [form] = Form.useForm();
  const [selectedUser, setSelectedUser] = useState(null);
  const [subscriptions, setSubscriptions] = useState([]);
  const options = useMemo(() => countryList().getData(), []);
  const navigate = useNavigate();

  const handleGetUsers = useCallback(async () => {
    if (user) {
      try {
        const response = await axios.post(webAPIs.getUsers, {
          user: user.id,
        });
        const data = response.data.users.filter((item) =>
          user.role === 3
            ? item !== null && item.role !== 0
            : user.role === 1
              ? item !== null && item.role === 0
                ? item.ownerId === user.id
                : item !== null && item.role !== 0
              : item !== null
        );
        setUsers(data);
      } catch (e) {
        // handle error
      } finally {
        setLoading(false);
      }
    } else {
      console.log('error');
    }
  }, [user]);

  const getSubscriptions = useCallback(async () => {
    try {
      const { data } = await axios.get(webAPIs.getSubscriptions);
      setSubscriptions(data.data);
    } catch (error) {
      console.error(error);
      notification.error({
        message: "",
        description: error.response?.data?.message || error.message || "An error occurred",
        duration: 5,
      });
    }
  }, []);

  useEffect(() => {
    const auth = JSON.parse(localStorage.getItem("auth"));
    if (auth) {
      handleGetUsers();
      if (user?.role === 3) {
        getSubscriptions();
      }
    } else {
      navigate('/');
    }
  }, [user]);


  const deleteUser = async (userId) => {
    axios
      .post(webAPIs.deleteUser, {
        id: userId,
      })
      .then((res) => {
        if (res.data.success) {
          handleGetUsers();
        }
      });
  };

  const resetPassword = async (userEmail) => {
    axios
      .post(webAPIs.resetPassword, {
        email: userEmail,
      })
      .then((res) => {
        if (res.data.success) {
          notification.success({
            description: "Password has been reset to 12345678.",
            duration: 1,
          });
        }
      });
  };

  const showModal = (user = null) => {
    if (user) {
      form.setFieldsValue({
        firstName: user.firstName,
        lastName: user.lastName,
        email: user.email,
        phoneNumber: user.phoneNumber,
        street: user.street,
        postalCode: user.postalCode,
        city: user.city,
        country: user.country,
        status: user.status,
        companyName: user.companyName,
        role: user.role,
        temp: user.temp,
        systemPrompt: user.systemPrompt,
        subscription: user.subscriptionId,
      });
      setSelectedUser(user);
    } else {
      form.setFieldsValue({
        firstName: "",
        lastName: "",
        email: "",
        phoneNumber: "",
        street: "",
        postalCode: "",
        city: "",
        country: "",
        status: "",
        companyName: "",
        role: 1,
        temp: 0.1,
        systemPrompt:
          'You are a qualitative market researcher with the task of analyzing and summarizing several interviews conducted between an interviewer and a respondent about a specific topic, each interview beginning with "******" and ending with "******"',
        subscription: 2,
      });
    }
    setIsModalVisible(true);
  };

  const handleOk = () => {
    form
      .validateFields()
      .then(async (values) => {
        form.resetFields();
        setIsModalVisible(false);
        // Call your API to add a user
        console.log(values);
        try {
          let response;
          if (selectedUser) {
            response = await axios.post(webAPIs.changeUser, {
              user: {
                ...values,
                id: selectedUser.id,
                sourceId: user.id,
              },
            });
          } else {
            response = await axios.post(webAPIs.addUser, {
              user: { ...values, sourceId: user.id },
            });
          }
          if (response.data.success) {
            handleGetUsers();
          }
        } catch (e) {
          // handle error
        } finally {
          setLoading(false);
        }
      })
      .catch((info) => {
        console.log("Validate Failed:", info);
      });
  };

  const columns = [
    {
      title: "FirstName",
      dataIndex: "firstName",
      width: 100,
      key: "firstName",
      fixed: "left",
    },
    {
      title: "LastName",
      dataIndex: "lastName",
      width: 100,
      key: "lastName",
      fixed: "left",
    },
    {
      title: "Email",
      dataIndex: "email",
    },
    {
      title: "Phone",
      dataIndex: "phoneNumber",
    },
    {
      title: "PostalCode",
      dataIndex: "postalCode",
    },
    {
      title: "Street",
      dataIndex: "street",
    },
    {
      title: "City",
      dataIndex: "city",
    },
    {
      title: "Country",
      dataIndex: "country",
    },
    {
      title: "CompanyName",
      dataIndex: "companyName",
    },
    {
      title: "Status",
      dataIndex: "status",
      render: (status) => (status ? "True" : "False"),
    },
    {
      title: "Role",
      dataIndex: "role",
      render: (role) =>
        role === 0
          ? "Account"
          : role === 1
            ? "AccountOwner"
            : role === 2
              ? "Admin"
              : "SuperAdmin",
    },
    {
      title: "SystemPrompt",
      dataIndex: "systemPrompt",
      render: (systemPrompt) => (
        <p className="truncate w-40 block text-base">{systemPrompt}</p>
      ),
    },
    {
      title: "Temperature",
      dataIndex: "temp",
    },
    ...(user && user.role === 3
      ? [
        {
          title: "SubscriptionName",
          dataIndex: "subscriptionName"
        },
        {
          title: "AdvancedRights",
          dataIndex: "advancedRights",
          render: (status) => (status ? "True" : "False"),
        },
        {
          title: "Model",
          dataIndex: "modelName",
        },
        {
          title: "PromptPrice / 1M",
          dataIndex: "promptPrice",
        },
        {
          title: "CompletionPrice / 1M",
          dataIndex: "completionPrice",
        },
        {
          title: "ContextLength",
          dataIndex: "contextLength",
          render: (length) => formatNumber(length),
        },
        {
          title: "TotalTokensAllTime",
          dataIndex: "totalTokensAllTime",
          render: (token) => formatNumber(token),
        },
        {
          title: "TotalTokensLast30Days",
          dataIndex: "totalTokensLast30Days",
          render: (token) => formatNumber(token),
        },
        {
          title: "TokenCosts",
          dataIndex: "tokenCosts",
        },
        {
          title: "TokenCosts30Days",
          dataIndex: "tokenCosts30Days",
        },
      ]
      : []),
    {
      title: "Action",
      key: "action",
      fixed: "right",
      width: 200,
      render: (text, record) => (
        <div>
          <Button onClick={() => showModal(record)}>Change</Button>
          <Button onClick={() => deleteUser(record.id)}>Delete</Button>
          <Button onClick={() => resetPassword(record.email)}>
            Reset Password
          </Button>
        </div>
      ),
    },
  ];

  const setRoles = (userRole) => {
    const allRoles = [
      { label: "Super Admin", value: 3 },
      { label: "Admin", value: 2 },
      { label: "Team Account Owner", value: 1 },
      { label: "Team Member", value: 0 },
    ];
    if (userRole === 3) {
      return allRoles.filter((item) => item.value !== 0);
    } else if (userRole > 1) {
      return allRoles.filter(
        (role) =>
          allRoles.findIndex((r) => r.value === userRole) <=
          allRoles.findIndex((r) => r.value === role.value)
      );
    } else {
      return allRoles.filter((item) => item.value <= userRole);
    }
  };

  const handleAddUser = () => {
    const length = users.length;
    switch (user.subscriptionId) {
      case 1:
        if (length > 2) {
          console.log('Upgrade subscription');
          subscriptionAlert();
          return;
        }
        break;
      case 2:
        if (length > 1) {
          console.log('Upgrade subscription');
          subscriptionAlert();
          return;
        }
        break;
      case 3:
        if (length > 2) {
          console.log('Upgrade subscription');
          subscriptionAlert();
          return;
        }
        break;
      case 4:
        if (length > 4) {
          console.log('Upgrade subscription');
          subscriptionAlert();
          return;
        }
        break;
      default:
        break;
    }
    setSelectedUser(null);
    showModal();
  }

  return (
    <div className="p-5 w-full flex flex-col gap-5">
      <div className="w-full flex justify-end">
        <Button
          className="w-[20%] flex items-center justify-center text-white bg-sidebarColor"
          onClick={() => {
            handleAddUser();
          }}
        >
          {user && user.role > 1 ? "Add User" : "Invite User"}
        </Button>
      </div>
      {loading ? (
        <Spin className="w-full h-screen" />
      ) : (
        <div className="overflow-x-auto bg-white">
          <Table
            dataSource={users}
            columns={columns}
            rowKey="id"
            pagination={{ defaultPageSize: 10 }}
          />
        </div>
      )}
      <Modal
        okButtonProps={{ className: "bg-[#007bff]" }}
        title="Add User"
        open={isModalVisible}
        onOk={handleOk}
        onCancel={() => setIsModalVisible(false)}
        width="80%"
      >
        <Form
          labelCol={{ span: 6 }}
          wrapperCol={{ span: 16 }}
          form={form}
          autoComplete="off"
        >
          <Row gutter={16}>
            <Col span={12}>
              <Form.Item
                label="FirstName"
                name="firstName"
                rules={[
                  {
                    required: true,
                    message: "Please input user name!",
                  },
                ]}
              >
                <Input autoComplete="off" />
              </Form.Item>
              <Form.Item
                label="LastName"
                name="lastName"
                rules={[
                  {
                    required: true,
                    message: "Please input user name!",
                  },
                ]}
              >
                <Input autoComplete="off" />
              </Form.Item>
              <Form.Item
                label="Email"
                name="email"
                rules={[
                  {
                    type: "email",
                    required: true,
                    message: "Please input email!",
                  },
                ]}
              >
                <Input autoComplete="off" />
              </Form.Item>
              <Form.Item
                label="Street"
                name="street"
              >
                <Input />
              </Form.Item>
              <Form.Item
                label="City"
                name="city"
                rules={[
                  {
                    required: true,
                    message: "Please input user city!",
                  },
                ]}
              >
                <Input />
              </Form.Item>
              <Form.Item
                label="Country"
                name="country"
                rules={[
                  {
                    required: true,
                    message: "Please select country!",
                  },
                ]}
              >
                <Select options={options} />
              </Form.Item>
              <Form.Item
                label="PostalCode"
                name="postalCode"
                rules={[
                  {
                    required: true,
                    message: "Please input postalCode!",
                  },
                ]}
              >
                <Input />
              </Form.Item>
              <Form.Item
                label="phone"
                name="phoneNumber"
                rules={[
                  {
                    required: true,
                    message: "Please input phone number!",
                  },
                ]}
              >
                <PhoneInput placeholder="Enter phone number" />
              </Form.Item>
              <Form.Item
                label="CompanyName"
                name="companyName"
              >
                <Input placeholder="Please input CompanyName" />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item label="Status" name="status" valuePropName="checked">
                <Checkbox />
              </Form.Item>
              {user && user.role && (
                <Form.Item
                  label="role"
                  name="role"
                  rules={[
                    {
                      required: true,
                      message: "Please input role!",
                    },
                  ]}
                >
                  <Select
                    // value={role}
                    options={setRoles(user.role).map((role) => ({
                      label: role.label,
                      value: role.value,
                    }))}
                  />
                </Form.Item>
              )}
              <Form.Item name="temp" label="Temperature">
                <Slider
                  min={0}
                  max={1}
                  step={0.1}
                  marks={{
                    0: "0",
                    0.2: "0.2",
                    0.4: "0.4",
                    0.6: "0.6",
                    0.8: "0.8",
                    1: "1",
                  }}
                />
              </Form.Item>
              <Form.Item
                label="SystemPrompt"
                name="systemPrompt"
                rules={[
                  {
                    required: true,
                    message: "Please input systemPrompt!",
                  },
                ]}
              >
                <Input.TextArea />
              </Form.Item>
              {user.role === 3 &&
                <Form.Item
                  label="subscription"
                  name="subscription"
                  rules={[
                    {
                      required: true,
                      message: "Please input subscription",
                    }
                  ]}
                >
                  <Select
                    options={subscriptions.map((sub) => ({
                      label: sub.name,
                      value: sub.id
                    }))}
                  />
                </Form.Item>}
              {!selectedUser && (
                <Form.Item
                  label="Password"
                  name="password"
                  rules={[
                    {
                      required: true,
                      message: "Please input password!",
                    },
                  ]}
                >
                  <Input.Password autoComplete="off" />
                </Form.Item>
              )}
            </Col>
          </Row>
        </Form>
      </Modal>
    </div>
  );
};

export default ControlUsers;
