import Footer from "../components/Footer";
import { Link } from "react-router-dom";

const SubscriptionSuccessful = () => {
  return (
    <div className="min-h-screen flex items-center justify-center bg-intro">
      <div className="logo-area w-full max-w-md">
        <img
          src={`${process.env.PUBLIC_URL}/insightlab_gold.png`}
          alt="logo"
          className="hidden sm:block"
        />
        <img
          src={`${process.env.PUBLIC_URL}/logo.png`}
          alt="logo"
          className="block sm:hidden p-2"
        />
      </div>
      <div className="bg-login p-8 rounded shadow-md w-full max-w-md">
        <div className="flex items-center justify-center mb-10">
          <div className="w-12 h-12 flex items-center justify-center text-white bg-green-500 text-xl font-bold rounded-full"> ✓ </div>
        </div>
        <h2 className="text-2xl font-bold mb-10 text-gray-800 text-center">Subscription updated successfully</h2>
        <div className="text-center mt-6">
          <Link to="/dashboard" className="submit-btn w-full py-2 px-4 rounded-md text-white text-sm font-bold">
            {" "}
            Dashboard
          </Link>
        </div>
      </div>
      <Footer currentPage="Verify Email Successful" />
    </div>
  );
};

export default SubscriptionSuccessful;
