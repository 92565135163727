import React, { useEffect } from "react";
import Roles from "../components/Roles";
import Footer from "../components/Footer";
import { Button } from "antd";
import { useNavigate } from "react-router-dom";

const DashboardRoles = () => {
  const navigate = useNavigate();
  useEffect(() => {
    const auth = JSON.parse(localStorage.getItem("auth"));
    if (auth) {
      navigate("/dashboard");
    }
    const loadScripts = () => {
      const iubConfig = document.createElement("script");
      iubConfig.type = "text/javascript";
      iubConfig.innerHTML = `
        var _iub = _iub || [];
        _iub.csConfiguration = {"siteId":3721568,"cookiePolicyId":53847430,"lang":"en-GB"};
      `;
      document.head.appendChild(iubConfig);

      const scripts = [
        "https://cs.iubenda.com/autoblocking/3721568.js",
        "//cdn.iubenda.com/cs/gpp/stub.js",
        "//cdn.iubenda.com/cs/iubenda_cs.js",
      ];

      scripts.forEach((src) => {
        const script = document.createElement("script");
        script.src = src;
        script.async = true;
        document.head.appendChild(script);
      });
    };

    loadScripts();
  }, []);

  return (
    <div className="sm:h-screen flex flex-col">
      <header className="sm:px-4 sm:pt-4 sm:flex sm:justify-between sm:h-[8%]">
        <img
          src={`${process.env.PUBLIC_URL}/insightlab_gold.png`}
          alt="logo"
          className="hidden sm:block"
        />
        <img
          src={`${process.env.PUBLIC_URL}/logo.png`}
          alt="logo"
          className="block sm:hidden bg-[#464646] p-2"
        />
        <div className="flex flex-col justify-center items-center gap-3">
          <span className="sm:text-3xl font-bold text-lg">
            What do you want to do?
          </span>
        </div>
        <div className="sm:flex gap-3 hidden">
          <Button
            type="Link"
            className="bg-sidebarColor text-white"
            onClick={() => {
              navigate("/sign-in");
            }}
          >
            Sign in
          </Button>
          <Button
            type="Link"
            className="bg-sidebarColor text-white"
            onClick={() => {
              navigate("/sign-up");
            }}
          >
            Sign up
          </Button>
        </div>
      </header>
      <div className="flex-1">
        <Roles
          setSystemRole={(data) => {
            navigate("sign-in");
          }}
        />
      </div>
      <Footer />
    </div>
  );
};

export default DashboardRoles;
