import React, { useEffect, useState, useContext } from "react";
import axios from "axios";
import { webAPIs } from "../config/webAPIs";
import { notification } from "antd";
import OnBoardingItem from "../components/OnboardingItem";
import { useNavigate } from "react-router-dom";

const Roles = (props) => {
  const auth = JSON.parse(localStorage.getItem("auth"));
  const [systemRoles, setSystemRoles] = useState([]);
  const navigate = useNavigate();

  useEffect(() => {
    const fetch = async () => {
      try {
        const response = await axios.get(webAPIs.getSystemRoles);
        if (response.data.success) {
          setSystemRoles(response.data.data);
        }
      } catch (e) {
        console.log(e.response?.data?.error);
        notification.error({
          description: e.response?.data?.error || "Server Error",
          duration: 2,
        });
      }
    };
    fetch();
  }, []);

  const handleSystemRoleClick = (systemRole) => {
    if (auth) {
      if (systemRole.insertVariableIds.length) {
        props.setSystemRole(systemRole);
      } else {
        notification.error({
          description: 'This SystemRole does not have insertVariables. Select other systemRole',
          duration: 2,
        });
      }
    } else {
      navigate("/sign-in");
    }
  };

  return (
    <div className="grid grid-cols-1 sm:grid-cols-3 gap-5 p-4">
      {systemRoles.map((item) => (
        <div className="flex justify-center" key={item.id}>
          <div onClick={() => handleSystemRoleClick(item)}>
            <OnBoardingItem
              title={item.title}
              description={item.caption}
              content={item.icon}
            />
          </div>
        </div>
      ))}
    </div>
  );
};

export default Roles;
