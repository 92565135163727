import React, { useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { webAPIs } from "../config/webAPIs";
import {
  notification,
  Button,
  Form,
  Input,
  Row,
  Col,
} from 'antd';
import axios from 'axios';

const ResetPassword = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [form] = Form.useForm();
  const navigate = useNavigate();
  const { token } = useParams(); // The reset token from the URL

  const handleFormSubmit = async (values) => {
    if (values.password !== values.confirmPassword) {
      notification.error({
        message: "Password Error",
        description: "Passwords do not match!",
        duration: 5,
      });
      return;
    }

    try {
      setIsLoading(true);
      // Replace with your backend API endpoint for password reset:
      const { data } = await axios.post(`${webAPIs.resetPasswordConfirmation}/${token}`, {
        password: values.password,
      });
      setIsLoading(false);
      if (data.success) {
        notification.success({
          message: "Success",
          description: data.message,
          duration: 5,
        });
        navigate('/sign-in'); // Redirect the user to the login page
      } else {
        notification.error({
          message: "Password Reset Error",
          description: data.message,
          duration: 5,
        });
      }
    } catch (error) {
      setIsLoading(false);
      notification.error({
        message: "",
        description: error.response?.data?.message || error.message || "An error occurred",
        duration: 5,
      });
    }
  };

  return (
    <div className="min-h-screen flex items-center justify-center bg-black">
      <div className="bg-white p-8 rounded-lg shadow-lg w-full max-w-xl mx-auto">
        <h2 className="text-2xl font-semibold mb-6 text-gray-900 text-center">
          Reset Your Password
        </h2>
        <Form
          form={form}
          onFinish={handleFormSubmit}
          layout="vertical"
          autoComplete="off"
        >
          <Row gutter={16}>
            <Col span={24}>
              <Form.Item
                label="Password"
                name="password"
                rules={[
                  { required: true, message: "Please input your new password!" },
                ]}
                hasFeedback
              >
                <Input.Password />
              </Form.Item>
            </Col>
            <Col span={24}>
              <Form.Item
                label="Confirm Password"
                name="confirmPassword"
                dependencies={["password"]}
                hasFeedback
                rules={[
                  {
                    required: true,
                    message: "Please confirm your new password!",
                  },
                  ({ getFieldValue }) => ({
                    validator(_, value) {
                      if (!value || getFieldValue("password") === value) {
                        return Promise.resolve();
                      }
                      return Promise.reject(
                        new Error(
                          "The two passwords that you entered do not match!"
                        )
                      );
                    },
                  }),
                ]}
              >
                <Input.Password />
              </Form.Item>
            </Col>
            <Col span={24}>
              <Form.Item>
                <Button
                  type="primary"
                  htmlType="submit"
                  block
                  loading={isLoading}
                  className="bg-blue-600"
                >
                  Reset Password
                </Button>
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </div>
    </div>
  );
};

export default ResetPassword;