import React from "react";

const OnBoardingItem = ({ title, description, content }) => {
  return (
    <div className="flex flex-col justify-start bg-white shadow-md items-center gap-3 rounded-lg overflow-hidden max-w-[450px] h-full hover:cursor-pointer hover:shadow-2xl hover:scale-105 transition duration-300 ease-in-out">
      <h3 className="text-lg sm:text-2xl font-semibold text-gray-800 mt-4">
        {title}
      </h3>
      <img
        src={content}
        alt={title}
        className="w-full max-w-[200px] object-contain p-4"
      />
      <p className="text-sm font-normal text-gray-600 mb-4 px-3 text-center">
        {description}
      </p>
    </div>
  );
};

export default OnBoardingItem;
